export const HOME_PAGE = "/";
export const OFFERS_PAGE = "/offers";
export const GET_OFFER_DETAILS_PAGE = (id) => `/offer/${id}`;
export const REFERRALS_PAGE = "/refer";
export const CONTEST_PAGE = "/contest";
export const GET_CONTEST_DETAILS_PAGE = (id) => `${CONTEST_PAGE}/${id}`;
export const WALLET_PAGE = "/wallet";
export const PROFILE_PAGE = "/profile";
export const REFERRAL_LIST_PAGE = "/referral-list";
export const REDEEM_PAGE = "/redeem";
export const SETTINGS_PAGE = "/settings";
export const FAQ_PAGE = "/faq";
export const HELP_PAGE = "/help";
export const TUTORIAL_PAGE = "/tutorial";
export const PRIVACY_PAGE = "/privacy-policy";
export const TERMS_CONDITIONS_PAGE = "/terms-conditions";
export const LANGUAGE_PAGE = "/language";
export const CONTACT_US_PAGE = "/contact-us";
export const APP_STATS_PAGE = "/app-stats";
export const LOGIN_PAGE = "/login";
export const SIGNUP_PAGE = "/signup";
export const SPIN_WIN_PAGE = "/spin-win";
export const GAMES_PAGE = "/games";
export const MAF_PAGE = "/MAF";
export const DAILY_CHECK_IN_PAGE = "/daily-check-in";
export const SUSPICIOUS_DEVICE = "/suspicious-device";
export const REFERRAL_SIGNUP = "/referral-signup";
export const GAME_OFFERS_PAGE = "/play-earn";
